/**
 * Custom CSS
 * ===========================================================
 */


/* Navigation
-------------------------------------------------------------- */

.navbar-custom {
  background-color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 768px) {

  .navbar-nav {
    float: none;
    text-align: center;

    &>li {
      display: inline-block;
      float: none;
    }

  }


}

/* Hero
-------------------------------------------------------------- */

.intro {
  padding-top: 55px;
  padding-bottom: 30px;
  background: url('../img/dwyer-portrait.webp') no-repeat center center scroll;

  @media (min-width: 0) and (max-width: 767px) {
    background-size: 100%;
  }

  .intro-body {
    padding-top: 30px;
    // background-color: rgba(0, 0, 0, 0.5);

    .brand-heading {
      margin-bottom: 5px;
      font-size: 17px;
    }

    .intro-text {
      margin-bottom: 280px;
      font-size: 13px;
    }

  }

  .btn-circle {
    margin: 15px;
    padding: 0 10px;
    width: 40px;
    height: 40px;
    font-size: 27px;
  }

}

@media (min-width: 375px) {

  .intro {

    .intro-body {

      .brand-heading {
        margin-bottom: 7px;
        font-size: 20px;
      }

      .intro-text {
        font-size: 15px;
      }

    }

  }

}

@media (min-width: 768px) {

  .intro {

    .intro-body {
      display: block;
      vertical-align: top;

      .brand-heading {
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: 38px;
      }

      .intro-text {
        margin-bottom: 5px;
        font-size: 20px;
      }

    }

  }

}

@media (min-width: 1440px) {

  .intro {

    .intro-body {

      .brand-heading {
        font-size: 46px;
      }

      .intro-text {
        font-size: 24px;
      }

    }

  }

}

@media (min-width: 1920px) {

  .intro {

    .intro-body {

      .brand-heading {
        margin-top: 60px;
      }

    }

  }

}


/* Sections
-------------------------------------------------------------- */

.content-section {
  padding-top: 60px;
  padding-bottom: 35px;

  h2 {
    font-size: 21px;
  }

  h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #888;
  }

}

.text-center {

  ul {
    text-align: left;
    display: inline-block;
  }

}

.image-grid {

  a[rel=lightbox] {
    margin-bottom: 15px;
    display: inline-block;
    background-color: #fff;

    img {
      transition: opacity 300ms ease;
    }

    img:hover {
      opacity: 0.7;
    }

  }

}

@media (min-width: 768px) {

  .content-section {

    h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 24px;
    }

  }

  .image-grid {

    a[rel=lightbox] {
      margin-bottom: 0;
      margin-left: 5px;
      margin-right: 5px;
    }

  }

}

@media (min-width: 1280px) {

  .content-section {
    padding-top: 75px;
    padding-bottom: 40px;
  }

}

/* Call to Action section
-------------------------------------------------------------- */

#call-to-action {
  background: url(../img/contact-background.jpg) no-repeat center center scroll;
  background-size: cover;
}


/* Audio section
-------------------------------------------------------------- */

#audio {
  background: url(../img/audio-background.jpg) no-repeat center center scroll;
  background-size: cover;
}


/* Shop section
-------------------------------------------------------------- */

#shop {

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
  }

}

@media (min-width: 768px) {

  #shop {

    p {
      max-width: 100%;
    }

    ul {
      margin-top: 5px;
      margin-bottom: 30px;
      padding-left: 115px;
    }

  }

}


/* Gallery section
-------------------------------------------------------------- */

#gallery {

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  p:first-of-type {

    a {
      margin-top: 15px;
      margin-bottom: 15px;
      width: 129px;
      height: 129px;
      overflow: hidden;
      background-color: transparent !important;
    }

    a:last-child {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 495px;
      height: auto;
      display: block;
      text-align: center;
    }

  }

}

@media (min-width: 768px) {

  #gallery {
    text-align: center;

    p:first-of-type {
      margin-left: auto;
      margin-right: auto;
      max-width: 717px;
      display: block;
    }

  }

}


/* Studio section
-------------------------------------------------------------- */

#studio {
  background: url(../img/shop-background.jpg) no-repeat center center scroll;
  background-size: cover;

  .studio-col {
    text-align: left;

    h3 {
      margin-bottom: 10px;
    }

    ul {
      margin-bottom: 30px;
      padding-left: 0;
      list-style: none;
    }

  }

  .csd-studio-lists {
    padding-left: 30px;
    padding-right: 30px;
    max-width: 900px;

    @media (min-width: 768px) {
      margin-left: auto;
      margin-right: auto;
    }

    @media (min-width: 1024px) {
      padding-left: 150px;
      padding-right: 0;
      max-width: 1000px;
    }
  }

}

/* Contact section
-------------------------------------------------------------- */

#contact {
  // background: url(../img/contact-background.jpg) no-repeat center center scroll;
  // background-size: cover;
}


/* footer
-------------------------------------------------------------- */

footer {
  background-color: #222;
}